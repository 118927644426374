<template>
  <div class="inside-os report-os">
    <div class="com-title">
      <span>报表查询
        <em>&gt;&gt;</em>未结明细
      </span>
 
    </div>
    <div class="report">
      <table class="com-table">
        <tr>
          <th style="width:158px">单号</th>
          <th style="width:120px">下注时间</th>
          <th style="width:150px">类型</th>
          <th>玩法</th>
          <th width="30">盘口</th>
          <th width="80px">赔率</th>
          <th width="80">金额</th>
          <th width="80">退水</th>
          <th width="100">可赢金额</th>
          <th width="50" v-if="hltjgame.chedanadvance>0">操作</th>
        </tr>
        <tr v-for="item in list" :key="item.orderid">
          <td>{{item.touzhufangshi}}{{item.orderid}}</td>
          <td>{{item.optime|substrSome}}</td>
          <td>
            {{item.roomcn}}<br>
            <span class="green">第{{item.expect}}期 </span>
          </td>
          <td><span class="blue">{{item.iteminfo}} {{item.ordernum}}</span> </td>
          <td>{{item.pan}}</td>
          <td> <span class="red">{{item.winodds}}</span> </td>
          <td>{{item.amount}}</td>
          <td>{{item.tuishui}}</td>
          <td> <span class="blue">{{item.kymoney}}</span> </td>
          <td v-if="hltjgame.chedanadvance>0">
            <a @click="tuimaon(item)"  href="javascript:" class="blue">退码</a>
        
          </td>
        </tr>
        <tr v-if="list.length>0">
          <th>合计</th>
          <th colspan="5">{{list.length}}</th>
          <th>{{Number(tAmount).toFixed(2)}}</th>
          <th>{{Number(tTuishui).toFixed(2)}}</th>
          <th>{{Number(tKymoney).toFixed(2)}}</th>
          <th v-if="hltjgame.chedanadvance>0"></th>
        </tr>
        <tr v-if="list.length==0">
          <td :colspan="hltjgame.chedanadvance>0?10:9" class="ctr">没有数据...</td>
        </tr>
      </table>

    </div>
    <div class="page">
      <el-pagination :page-sizes="[20,50,100,200]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="sizes,total, prev, pager, next, jumper" :total="totals">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      // 合计
      tAmount: 0,
      tTuishui: 0,
      tKymoney: 0,
      // 条查
      queryParam: {},
      // state(订单状态:0 未结算 1 已结算 3 系统退单 4 用户撤单 -1 全部)
      state: 0,
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      totalPage: 1,
      totals: 1,
      list: [],
    };
  },

  components: {},
  created() {

    // 获取游戏统计数据
    this.getGameTJ()
    // this.getList();
  },
  computed: {
    ...mapState(["userInfo",'hltjgame']),
  },
 watch:{
  hltjgame(val){
    //  console.log(val,96)
    this.pages.pagenum=1
    this.getList();
  }
 },
 beforeDestroy(){
  this.$store.commit('SETHLTJGAME',{})
 },
  methods: {
    tuimaon(item){
let obj={
  uuid: this.userInfo.Uuid,
      sid: this.userInfo.Sid,
      orderid:item.orderid
}
 
   this.$http.post('ordertuima',obj).then((res)=>{
    if(res.Status){
      this.$message.success(res.Msg);
      this.getGameTJ()
      // this.getList()
    }else{
      this.$message.error(res.Msg);
    }
   })



    },
    getGameTJ(){
      let hours = new Date().getHours();
      let date = null;
 
      if (hours < 6) {
        date = this.splitDate(this.getBeforeDate(1));
    
      } else {
        date = this.splitDate(this.getBeforeDate(0));
    
      }
      let obj={
        uuid: this.userInfo.Uuid,
      sid: this.userInfo.Sid,
      state: this.state,
         date
      }
   this.$http.post('getamountgroupbygame',obj).then((res)=>{
    if(res.Status){
   let resArr=JSON.parse(res.Msg);
   console.log(resArr,145)
   let arr=resArr.filter(item=>item.amount>0)
   arr.sort((a,b)=>{
    return b.amount-a.amount
   })
   console.log(arr,155)
   this.$store.commit('SETHLTJLIST',arr)
  
   if(arr.length>0){
    if(!this.hltjgame.roomeng){

      this.$store.commit('SETHLTJGAME',arr[0])
    }else{
      this.getList();
    }
  
    
   }else{
    this.list=[]
   }

 
    }else{
      this.$message.error(res.Msg);
    }
   })


    },
    getList() {
      this.tAmount = 0;
      this.tTuishui = 0;
      this.tKymoney = 0;
      let hours = new Date().getHours();
      let begindate = null;
      let enddate = null;
      
      if (hours < 6) {
        begindate = this.splitDate(this.getBeforeDate(1));
        enddate = this.splitDate(this.getBeforeDate(1));
      } else {
        begindate = this.splitDate(this.getBeforeDate(0));
        enddate = this.splitDate(this.getBeforeDate(0));
      }
     
      let obj = {
        uuid: this.userInfo.Uuid,
      
        sid: this.userInfo.Sid,
        state: this.state,
        pagenum: this.pages.pagenum,
        pagecount: this.pages.pagecount,
        begindate,
        enddate,
        roomeng:this.hltjgame.roomeng
      };
      this.$http
        .post("getlotorderinfos", obj)
        .then((res) => {
          if (res.Status) {
            if (res.Msg) {
              let resObj = JSON.parse(res.Msg);
              // this.list = resData;
              this.list = resObj.data||[];
              // console.log(this.list,205);
              this.list.forEach((item) => {
                this.tAmount += Number(item.amount);
                this.tTuishui += Number(item.tuishui);
                item.kymoney = (
                  Number(item.amount) * Number(item.winodds) -
                  Number(item.amount) +
                  Number(item.tuishui)
                ).toFixed(2);
                this.tKymoney += Number(item.kymoney);
              });

              this.totalPage = resObj.maxpagenum;
              this.totals = resObj.maxrecordnum;
              // console.log(this.list, 5643);
            }else{
              this.list=[]
            }
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      this.pages.pagecount = val;
      this.pages.pagenum = 1;
      this.getList();
    },
    splitDate(time) {
      let arr = time.split("-");
      let str = "";
      arr.forEach((element) => {
        str += element;
      });
      return str;
    },
    // 当前页面
    handleCurrentChange(page) {
      this.pages.pagenum = page;
      this.getList();
    },
  },
};
</script>
<style  scoped>
.green {
  color: green;
}
.blue {
  color: blue;
}
.red {
  color: red;
  font-weight: bold;
}
.cublue {
  color: blue;
  font-weight: bold;
}
td {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}
.inside-os.report-os .com-title a {
  font-weight: 400;
  color: #4f4d4d;
}
.inside-os.report-os .report .com-table.rt-table {
  border-top: none;
}
a {
  text-decoration: none;
}
.inside-os.report-os .report .com-table td.win,
.inside-os.report-os .report .com-table td.win a {
  color: #2836f4;
}
.inside-os.report-os .report .com-table td.ctr {
  padding: 4px 0;
  text-align: center;
}

.inside-os.report-os .report .com-table td {
  /* padding: 4px 10px 4px 0; */
  text-align: center;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.inside-os .com-table {
  width: 100%;
}
.inside-os.report-os .report {
  margin-top: 5px;
}
.inside-os.report-os .com-title {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.inside-os .com-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
}
/* .inside-os {
  width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>